import React from "react";
import tw, { styled, css } from "twin.macro";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "styled-components/macro";
import _ from "lodash";
import moment from "moment-timezone";
import { hasEmptyDeep, titleCase, hasPassed } from "../utils";
import { TZ } from "../constants";

import WidgetCard from "./WidgetCard";
import CompetitionChip from "./CompetitionChip";
import LogoPlaceholder from "./../images/img-placeholder.svg";

const Content = styled.div`
  ${tw`flex justify-between items-center gap-4`}
`;

const Team = styled.div`
  ${tw`flex gap-2`}
`;
const SpacingDiv = styled.div`
  ${tw`w-0 sm:w-[50px]`}
`;
const Name = styled.div`
  ${tw`hidden sm:flex font-bold items-center w-[70px] sm:w-[120px]`}
`;

const ScoreNumber = styled.div`
  ${tw`font-bold text-4xl`}
`;

const TicketsContainer = styled.div`
  ${tw`mt-4 pt-4 border-t-[1px] border-gray-300 flex justify-center`}
`;

const TicketsButton = styled.a`
  ${tw`
    rounded-3xl hover:bg-red-700
    transition-[background-color] duration-200
    text-white font-semibold
    px-16 py-4 max-w-max 
  `}
  background-color: ${(props) => props.color}
  ${({ disabled }) => disabled && tw`pointer-events-none grayscale`}
`;

const ActionContainer = styled.div`
  ${tw`mt-4 pt-4 border-t-[1px] border-gray-300 flex flex-col items-center gap-2`}
`;

const StatLink = styled.a`
  ${tw`hover:opacity-80  px-2 py-1 text-center text-xs sm:w-[120px] sm:mx-2 rounded block border border-gray-300 cursor-pointer`}
`;
const LogoContainer = styled.div`
  ${tw`flex justify-center items-center sm:w-[20%] max-w-[20em] min-w-[50px] h-[100px]`}
`;

const VersusWidget = ({
  score,
  homeTeam,
  awayTeam,
  date,
  showBuyTicket,
  ticketLink,
  competition,
  leagueNodes,
  field,
  gameStatsLink,
  site,
  color,
  homeTeams = [],
}) => {
  const momentDate = moment(date);
  const isHomeGame = homeTeams.includes(
    _.get(homeTeam, "frontmatter.name", "").toLowerCase()
  );

  const league = leagueNodes.find(
    (l) => l.node.frontmatter.name === competition
  );
  const awayLogo = getImage(_.get(awayTeam, "frontmatter.logo"));
  const homeLogo = getImage(_.get(homeTeam, "frontmatter.logo"));
  return (
    <WidgetCard>
      <Content>
        <Team>
          <SpacingDiv />
          <LogoContainer>
            {awayLogo ? (
              <GatsbyImage image={awayLogo} alt="logo" />
            ) : (
              <LogoPlaceholder />
            )}
          </LogoContainer>
          <Name>{titleCase(_.get(awayTeam, "frontmatter.name"))}</Name>
        </Team>
        {hasEmptyDeep(score) ? (
          <div tw="text-center">
            <div tw="font-bold">
              <div>{moment.tz(momentDate, TZ).format("MMMM D, YYYY")}</div>
              <div>{moment.tz(momentDate, TZ).format("h:mm A dddd")}</div>
            </div>
            <div tw="text-xs">
              <div>
                {field
                  ? field
                  : titleCase(
                      _.get(homeTeam, "frontmatter.location.fieldName")
                    )}
              </div>
              <div>{_.get(homeTeam, "frontmatter.location.address")}</div>
            </div>
          </div>
        ) : (
          <>
            <ScoreNumber>{_.get(score, "away")}</ScoreNumber>
            <div tw="uppercase font-bold">Final</div>
            <ScoreNumber>{_.get(score, "home")}</ScoreNumber>
          </>
        )}
        <Team>
          <Name tw="font-bold">
            {titleCase(_.get(homeTeam, "frontmatter.name"))}
          </Name>
          <LogoContainer>
            {homeLogo ? (
              <GatsbyImage image={homeLogo} alt="logo" />
            ) : (
              <LogoPlaceholder />
            )}
          </LogoContainer>
          <SpacingDiv />
        </Team>
      </Content>
      {(isHomeGame || showBuyTicket) &&
        site === "dawgs" &&
        hasEmptyDeep(score) &&
        !hasPassed(momentDate) && (
          <TicketsContainer>
            <TicketsButton
              href={ticketLink}
              target="_blank"
              color={color}
              // disabled={hasPassed(momentDate)}
            >
              Buy Tickets
            </TicketsButton>
          </TicketsContainer>
        )}
      {site === "diamondbacks" && (
        <ActionContainer>
          <CompetitionChip competition={league} />
          {gameStatsLink && (
            <StatLink href={gameStatsLink} target="__blank">
              Full Game Stats
            </StatLink>
          )}
        </ActionContainer>
      )}
    </WidgetCard>
  );
};

export default VersusWidget;
