import { useState, useEffect } from "react"
import _ from "lodash"

const useTeams = ({ teamNodes, home, away }) => {
  const [homeTeam, setHomeTeam] = useState({})
  const [awayTeam, setAwayTeam] = useState({})

  useEffect(() => {
    const homeTeamIdx = _.findIndex(
      teamNodes,
      team =>
        _.get(team, "frontmatter.name", "").toLowerCase() === home.toLowerCase()
    )

    const awayTeamIdx = homeTeamIdx === 0 ? 1 : 0

    setHomeTeam(teamNodes[homeTeamIdx])
    setAwayTeam(teamNodes[awayTeamIdx])
  }, [teamNodes, home, away])

  return { homeTeam, awayTeam }
}

export default useTeams
