import React from "react"
import tw, { styled } from "twin.macro"
import "styled-components/macro"

const Container = styled.div`
  ${tw`
    w-full
    rounded-xl drop-shadow-sm
    p-5 bg-white
    `}
  ${({ fit }) => fit === true ? tw`h-fit` : tw`h-full`}
`

const WidgetCard = ({ children, fit = false }) => {
  return <Container fit={fit}>{children}</Container>
}

export default WidgetCard
