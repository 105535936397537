import React from "react";
import { graphql } from "gatsby";
import tw, { styled, css } from "twin.macro";
import "styled-components/macro";
import _ from "lodash";
import moment from "moment-timezone";
import Layout from "../components/Layout";
import { HOME_TEAMS, MAIN_COLOR, SITE } from "../config";

import WidgetCard from "@shared/components/WidgetCard";
import TopPerformersWidget from "@shared/components/TopPerformersWidget";
import InningStatsWidget from "@shared/components/InningStatsWidget";
import VersusWidget from "@shared/components/VersusWidget";
import { TZ } from "@shared/constants";
import useStats from "@shared/hooks/useStats";
import useTeams from "@shared/hooks/useTeams";
import { hasEmptyDeep } from "@shared/utils";

const Section = styled.div`
  ${tw`mb-6 grid gap-6`}

  ${({ childrenCount }) =>
    childrenCount &&
    css`
      @media (min-width: 1024px) {
        grid-template-columns: repeat(${childrenCount}, 1fr);
      }
    `}
`;

const LiveButtonContainer = styled.a`
  ${tw`
    bg-[#9561a9] text-white 
    flex items-center justify-center
    px-4 py-0.5 my-4
    text-sm font-bold 
    hover:not-disabled:opacity-80 transition-opacity 
    rounded-xl
  `}

  & > .material-icons {
    ${tw`mr-2 text-4xl`}
  }
`;

const Body = styled.div`
  ${tw`prose max-w-full`}

  > *:first-child {
    ${tw`mt-0`}
  }

  iframe {
    ${tw`w-full`}
  }
`;

const GamePost = ({
  data: {
    markdownRemark: {
      html,
      frontmatter: {
        seo,
        homeTeam: home,
        awayTeam: away,
        date,
        competition,
        promoDescriptions,
        gameStatsLink,
        stats,
        field,
      },
    },
    teamsData: { nodes: teamNodes },
    playersData: { nodes: playerNodes },
    allLeagues,
    homepage,
  },
  location,
}) => {
  const { inningStats, pitchingStats, topPerformers } = stats || {};

  const { inningRuns, score, hits, errors } = useStats({ inningStats });
  const { homeTeam, awayTeam } = useTeams({ teamNodes, home, away });

  // check if any of fields is empty, display is set to false
  const pitchingStatsDisplayArr = Object.keys(pitchingStats).map(
    (key) => !hasEmptyDeep(pitchingStats[key])
  );
  const showPitchingStatsSection = pitchingStatsDisplayArr.some((bool) => bool);
  const showInningStatsSection = !hasEmptyDeep([inningStats]);
  const showInningStatsWidget =
    showPitchingStatsSection || showInningStatsSection;

  const showTopPerformers = !hasEmptyDeep([topPerformers]);

  const visibleCount = [showInningStatsWidget, showTopPerformers].filter(
    (bool) => bool
  ).length;

  const watchLiveLink = homepage.frontmatter.hero.watchLiveLink;
  const teamSeasonLink = homepage.frontmatter.hero.teamSeasonLink;
  const leagueNodes = allLeagues.edges;
  return (
    <Layout
      noBg
      location={location}
      seoTitle={`${away} vs ${home} (${moment(date)
        .tz(TZ)
        .format("MMM D, YYYY h:mma")})`}
      useSeoForTitle
    >
      <Section>
        <VersusWidget
          score={score}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          date={date}
          competition={competition}
          leagueNodes={leagueNodes}
          field={field}
          gameStatsLink={gameStatsLink}
          site={SITE}
          color={MAIN_COLOR}
          homeTeams={HOME_TEAMS}
        />
      </Section>
      {/* {hasEmptyDeep(score) && !hasPassed(date) && (
        <Section>
          <LiveButtonContainer
            href={watchLiveLink || teamSeasonLink}
            target="_blank"
            disabled={_.isEmpty(watchLiveLink) && _.isEmpty(teamSeasonLink)}
          >
            <Icon name="videocam" /> <div>WATCH NOW</div>
          </LiveButtonContainer>
        </Section>
      )} */}

      {(showInningStatsWidget || showTopPerformers) && (
        <Section childrenCount={visibleCount}>
          {showInningStatsWidget && (
            <InningStatsWidget
              score={score}
              inningRuns={inningRuns}
              hits={hits}
              errors={errors}
              homeTeam={homeTeam}
              awayTeam={awayTeam}
              pitchingStats={pitchingStats}
              showPitchingStatsSection={showPitchingStatsSection}
              showInningStatsSection={showInningStatsSection}
            />
          )}
          {showTopPerformers && (
            <TopPerformersWidget
              players={playerNodes}
              topPerformers={topPerformers}
            />
          )}
        </Section>
      )}
      {html && (
        <Section>
          <WidgetCard>
            <Body dangerouslySetInnerHTML={{ __html: html }} />
          </WidgetCard>
        </Section>
      )}
    </Layout>
  );
};

export default GamePost;

export const pageQuery = graphql`
  query GamePostQuery(
    $id: String!
    $teams: [String]
    $topPerformers: [String]
  ) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        seo {
          title
          description
        }
        homeTeam
        awayTeam
        date
        rainOut
        competition
        gameStatsLink
        field
        promoDescriptions
        stats {
          topPerformers {
            player
            upperStats
            lowerStats
          }
          inningStats {
            home
            away
          }
          pitchingStats {
            win {
              player
              seasonRecord {
                wins
                losses
              }
              era
            }
            loss {
              player
              seasonRecord {
                wins
                losses
              }
              era
            }
            save {
              player
              seasonRecord {
                wins
                losses
              }
              era
            }
          }
        }
      }
    }
    teamsData: allMarkdownRemark(
      filter: { frontmatter: { mdType: { eq: "team" }, name: { in: $teams } } }
    ) {
      nodes {
        frontmatter {
          name
          logo {
            childImageSharp {
              gatsbyImageData(height: 75)
            }
          }
          location {
            abbreviation
            fieldName
            address
          }
        }
      }
    }
    playersData: allMarkdownRemark(
      filter: { fields: { slug: { in: $topPerformers } } }
    ) {
      nodes {
        frontmatter {
          name
          team
          avatar {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        fields {
          slug
        }
      }
    }
    allLeagues: allMarkdownRemark(
      filter: { frontmatter: { mdType: { eq: "league" } } }
    ) {
      edges {
        node {
          frontmatter {
            name
            color
            link
          }
        }
      }
    }
    homepage: markdownRemark(
      frontmatter: { templateKey: { eq: "index-page" } }
    ) {
      frontmatter {
        hero {
          watchLiveLink
          teamSeasonLink
        }
      }
    }
  }
`;
